
import "./style.scss";
import { Container } from "react-bootstrap";
import { Row, Col } from "antd";


function Section1() {


 
  return (
    <section className="section-1">
      {" "}
      <video
        loop
        autoPlay={true}
        playsInline
        muted
        className="section-1-Video full-width-video align-bottom"
      >
        <source src="/customizejewellery-bg-1.mp4" type="video/mp4" />
      </video>
      <div className="section1-bg">
        <div className="section1-secondDiv"></div>
        <div className="heading-div1 ">
          <h3 className="heding-text">Customize Our Collection</h3>
        </div>
        <Container>
          <Row>
            <div className="EmtyDiv"></div>
          </Row>
          <Row>
            <Col xs={0} sm={0} md={2} lg={3}></Col>
            <Col xs={24} sm={12} md={10} lg={9}>
              <div className="ornamans-mai-div">
                <div
                  className="ornamans"
                  onClick={() =>
                    window.open(
                      "https://customize.luckystargold.com/",
                      "_blank"
                    )
                  }
                >
                  <img
                    src="/ring22-gold.svg"
                    color="red"
                    className="img-fluid w-100 h-100 ornamans-1 "
                    alt="Image1"
                  />
                </div>
                <div
                  className="ornamans"
                  onClick={() =>
                    window.open(
                      "https://customize.luckystargold.com/",
                      "_blank"
                    )
                  }
                >
                  <img
                    src="/ring.svg"
                    color="red"
                    className="img-fluid w-100 h-100   ornamans-1 "
                    alt="Image1"
                  />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={12} md={10} lg={9}>
              <div className="ornamans-mai-div">
                <div
                  className="ornamans"
                  onClick={() =>
                    window.open(
                      "https://customize.luckystargold.com/",
                      "_blank"
                    )
                  }
                >
                  <img
                    src="/neclace-gold.svg"
                    color="red"
                    className="img-fluid w-100 h-100  ornamans-1 "
                    alt="Image1"
                  />
                </div>
                <div
                  className="ornamans"
                  onClick={() =>
                    window.open(
                      "https://customize.luckystargold.com/",
                      "_blank"
                    )
                  }
                >
                  <img
                    src="/bangle-gold.svg"
                    color="red"
                    className="img-fluid w-100 h-100   ornamans-1"
                    alt="Image1"
                  />
                </div>
              </div>
            </Col>
            <Col xs={0} sm={0} md={2} lg={3}></Col>
          </Row>
          <div className="ornamans-imgg">
            <Row gutter={{ xs: 2, sm: 4, md: 6, lg: 20, xl: 10 }}>
              <Col md={6} sm={6} lg={6}>
                <img
                  src="/bangle.png"
                  color="red"
                  className="oranamens-img"
                  alt="Image1"
                />
              </Col>
              <Col md={6} sm={6} lg={6}>
                <img
                  src="/chainn.png"
                  color="red"
                  className="oranamens-img"
                  alt="Image1"
                />
              </Col>
              <Col md={6} sm={6} lg={6}>
                <img
                  src="/neclease.png"
                  color="red"
                  className="oranamens-img"
                  alt="Image1"
                />
              </Col>
              <Col md={6} sm={6} lg={6}>
                <img
                  src="/thickbangle.png"
                  color="red"
                  className="oranamens-img"
                  alt="Image1"
                />
              </Col>
            </Row>
          </div>
        </Container>
        <br />
        <button
          className="custemize-btn-section-1 "
          onClick={() =>
            window.open("https://customize.luckystargold.com/", "_blank")
          }
        >
          Customize Jewellery
        </button>
      </div>
    </section>
  );
}

export default Section1;

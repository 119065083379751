import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.scss";
import Footer from "./components/footer";
import Header from "./components/header";
import Section1 from "./components/section1";
import Section2 from "./components/section2";
import Section3 from "./components/section3";
import Section4 from "./components/section4";
import VideoSection from "./components/video-section";
import ShareRedirect from "./components/share-redirect/share";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>  
              <Header />
              <VideoSection />
                <Section1 />
                <Section2 />
                <Section3 />
                <Section4 />
              <Footer />
            </>
          }
        />
     
     <Route path="/share" element={<ShareRedirect />} />
      </Routes>
    </>
  );
}

export default App;

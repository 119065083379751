
import "./style.scss";
import { Col, Container, Row } from "react-bootstrap";



function Section3() {
  
  return (
    <section className="section-3">
      <div>
        <video
          loop
          muted
          autoPlay={true}
          playsInline
          className="section-3-Video"
        >
          <source src="/sec3bgvideo2.mp4" type="video/mp4" />
        </video>
        <div className="sectin3-div">
          <Container>
            <Row>
              <Col sm={0} xs={0} lg={6}>
                <div className="sectin3-div-Image">
                  <Row>
                    <Col lg={12}>
                      <img className="sectin3-Image" src="/invest1.jpg" />
                    </Col>
                    <Col lg={6}>
                      <img className="sectin3-Image2" src="/invest2.jpg" />
                    </Col>
                    <Col lg={6}>
                      <img className="sectin3-Image2" src="/invest3.jpg" />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6}>
                <div className="Content-Txt1">
                  {" "}
                  Invest in Gold
                  <br /> Physically{" "}
                </div>
                <div className="Content-Txt2">
                  {" "}
                  Lets see the Gold Investment
                </div>
                <p style={{ fontSize: "14px" }}>
                  With our platform, you have the opportunity to purchase
                  digital gold that's directly backed by real, physical gold.
                  When you buy digital gold through our website, you're not just
                  completing a virtual transaction—you're securing ownership of
                  actual, physical gold that we safely store for you in trusted,
                  high-security vaults{" "}
                </p>

                <p>
                  {" "}
                  We understand how important it is to balance the convenience
                  of modern technology with the security of traditional assets.
                  That's why our service provides you with the best of both
                  worlds—enabling easy, hassle-free digital transactions while
                  giving you the peace of mind that comes with owning tangible
                  gold.
                </p>
                <p>
                  {" "}
                  Whether you're looking to trade your digital gold or
                  eventually convert it into physical gold bars or coins, we've
                  designed our platform to offer you maximum flexibility and
                  control over your investments. You can trust us to make
                  managing your gold simple, secure, and transparent.
                </p>
                <div className="invest-btn">
                  <button
                    className="defult-btn "
                    onClick={() =>
                      window.open(
                        "https://digigold.luckystargold.com/",
                        "_blank"
                      )
                    }
                  >
                    Investment In Gold
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
}

export default Section3;

import { Col, Container, Row } from "react-bootstrap";
import { BiLogoInstagram } from "react-icons/bi";
import {
  FaCcApplePay,
  FaCcMastercard,
  FaCcPaypal,
  FaCcVisa,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { LiaWhatsapp } from "react-icons/lia";
import { TiSocialFacebook } from "react-icons/ti";
import "./style.scss";

function Footer() {
  const handleLinkClick = (link: string) => {
    window.open(link, "_blank");
  };
  return (
    <div className="footer">
      <br />
      <Container>
        <main className="footer-container">
          <Row>
            <Col sm={6} md={6} lg={3}>
              <div className="logoContainer">
                <img
                  className="listFooter-logo"
                  src="/applogo.png"
                  alt="App Logo"
                />
                <br />
                <div className="social-cards">
                  <div className="social-cards-Box">
                    <TiSocialFacebook
                      onClick={() =>
                        window.open(
                          "https://www.facebook.com/luckystargoldllc",
                          "_blank"
                        )
                      }
                      size={15}
                    />
                  </div>
                  <div className="social-cards-Box">
                    <BiLogoInstagram
                      onClick={() =>
                        handleLinkClick(
                          "https://www.instagram.com/luckystargoldgcc/?hl=en"
                        )
                      }
                      size={15}
                    />
                  </div>
                  <div className="social-cards-Box">
                    <FaXTwitter
                      onClick={() =>
                        handleLinkClick("https://twitter.com/Luckystargold")
                      }
                      size={15}
                    />
                  </div>
                  <div className="social-cards-Box">
                    <LiaWhatsapp
                        onClick={() =>
                          handleLinkClick(
                            "https://wa.me/+971565084747"
                          )
                        }
                      size={20}
                    />
                  </div>
                </div>
              </div>
            </Col>

            <Col sm={6} md={6} lg={3}>
              <div
                className="list-container"
                style={{
                  zIndex: "1",
                }}
              >
                <div className="list-heading">Assistance</div>
                <ul>
                  <li>
                    <a
                      className="custom-div"
                      href="https://store.luckystargold.com/"
                      target="_blank"
                    >
                      Store
                    </a>
                  </li>

                  <li>
                    <a
                      className="custom-div"
                      href="https://customize.luckystargold.com/"
                      target="_blank"
                    >
                      Customization
                    </a>
                  </li>
                  <li>
                    <a
                      className="custom-div"
                      href="https://digigold.luckystargold.com/"
                      target="_blank"
                    >
                      Digital Gold
                    </a>
                  </li>
                  <li>
                    <a
                      className="custom-div"
                      href="https://auction.luckystargold.com/"
                      target="_blank"
                    >
                      Auction
                    </a>
                  </li>
                  {/* <li>
                    <div className="custom-div">Upload your design</div>
                  </li> */}
                </ul>
              </div>
            </Col>
            <Col sm={6} md={6} lg={3}>
              <div className="list-container">
                <div className="list-heading">Company</div>
                <ul>
                  <li>
                    <a
                      className="custom-div"
                      href="https://store.luckystargold.com/about"
                      target="_blank"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className="custom-div"
                      href="https://store.luckystargold.com/Contactus"
                      target="_blank"
                    >
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a
                      className="custom-div"
                      href="https://store.luckystargold.com/terms-and-conditions"
                      target="_blank"
                    >
                      Terms & Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      className="custom-div"
                      href="https://store.luckystargold.com/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm={6} md={6} lg={3}>
              <div
                className="list-container"
                style={{
                  zIndex: "1",
                }}
              >
                <div className="list-heading">Location</div>
                <ul>
                  <li>
                    <div></div>
                  </li>
                  <li>
                    <div className="custom-div">
                      <strong className="custom-div-Head">Address:</strong>
                      &nbsp;&nbsp;Shop No : 2 Near Gold Land Building,
                       Opp Gold Souk Metro,Gold Souk,
                       Deira, Dubai, UAE
                    </div>
                  </li>
                  <li
                    onClick={() =>
                      (window.location.href = `tel:+971 54 581 6161`)
                    }
                  >
                    <div className="custom-div" style={{ cursor: "pointer" }}>
                      <strong className="custom-div-Head">Phone:</strong> &nbsp;
                      +971 54 581 6161
                    </div>
                  </li>
                  <li
                    onClick={() =>
                      (window.location.href = `mailto:sales@luckystargold.com`)
                    }
                  >
                    <div className="custom-div" style={{ cursor: "pointer" }}>
                      <strong className="custom-div-Head">Email:</strong>{" "}
                      &nbsp;sales@luckystargold.com
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </main>
      </Container>
      <div className="final-footer">
        <Container>
          <div className="final-footer-inner">
            <span className="copyright-txt">
              COPYRIGHT © 2024 Luckystargold. All rights reserved
            </span>
            <span className="Payment-cards">
              <FaCcVisa size={40} color="#fff" />
              <FaCcMastercard size={40} color="#fff" />
              <FaCcPaypal size={40} color="#fff" />
              <FaCcApplePay size={40} color="#fff" />
            </span>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Footer;

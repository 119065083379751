
import "./style.scss";
import Image from "../../assets/images/Phone.svg";
import { Container } from "react-bootstrap";


function VideoSection() {
  
  return (
    <section className="section-video">
      <video
        loop
        autoPlay={true}
        playsInline
        muted
        className="section-video-play align-bottom"
      >
        <source type="video/mp4" src="/videosection.mp4" />
      </video>
      <Container>
        <div className="section-video-bg">
          <div className="text-white"> Making Charge </div>
          <h4>
            Craft Gold Jewellery With <br />
            Zero Making Charges!
          </h4>
          <button
            className="defult-btn-section-video "
            onClick={() =>
              window.open("http://store.luckystargold.com/", "_blank")
            }
          >
            Visit Store
          </button>
        </div>
      </Container>
      <div className="Contact-Div">
        <img
          className=""
          src={Image}
          alt="overlay"
          style={{ width: "35px", height: "35px" }}
        />
      </div>
    </section>
  );
}
export default VideoSection;

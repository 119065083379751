
import "./style.scss";

import { Col, Container, Row } from "react-bootstrap";



function Section4() {
  
  

  let storeData = [
    {
      img: "/neck-lace.png",
      header: "Eternal Grace – Necklace Collection",
      desc: "Elegant necklaces with radiant diamonds and gemstones set in gold and platinum.",
    },
    {
      img: "/mothiram.png",
      header: "Luxe Circles – Ring Collection",
      desc: "Exquisite rings featuring flawless gemstones and timeless designs.",
    },
    {
      img: "/kammal.png",
      header: "Golden Embrace – Bangle Collection",
      desc: "Beautiful bangles blending elegance with masterful craftsmanship.",
    },
    {
      img: "/vala.png",
      header: "Radiant Glow – Earring Collection",
      desc: "Dazzling earrings, from subtle studs to statement pieces.",
    },
  ];
  return (
    <section className="section-4 ">
      <video
        loop
        muted
        autoPlay={true}
        playsInline
        className="section-4-video full-width-video align-bottom"
      >
        <source src="/section4bgvideo-vmake.mp4" type="video/mp4" />
      </video>
      <div className="section4-bg">
        <div className="heading-textt">
          <h3> Purchase Our Collection </h3>
        </div>
        <br />
        <Container>
          <Row className="content-sub-divs-Desk g-3">
            {storeData.map((item: any) => (
              <Col xs={12} md={6} lg={3}>
                <div className="content-sub-divs">
                  <img src={item?.img} alt="Image1" />
                  <div className="content-txt1">{item.header}</div>
                  <div className="content-txt2">{item.desc}</div>
                </div>
              </Col>
            ))}
          </Row>
          <Row className="content-sub-divs-Mob g-3 ">
            <div className="Horizontal_PscrollBox" >
              {storeData?.map((item: any) => {
                return (
                  <Col xs={11} sm={6}>
                    <div
                      className="Horizontal_item"
                      style={{ backgroundImage: `url(${item?.img})` }}
                    >
                      <div className="content-txt1">{item.header}</div>
                      <div className="content-txt2">{item.desc}</div>
                    </div>
                  </Col>
                );
              })}
            </div>
          </Row>
        </Container>
        <div className="section4-btn">
          <a href="https://store.luckystargold.com/" target="_blank">
            <button className="defult-btn ">
              Visit Our Store{" "}
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Section4;

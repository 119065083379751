


import { Col, Container, Row } from "react-bootstrap";
import "./style.scss";

function Section2() {
 
  return (
    <section className="section-2">
      <video
        loop
        autoPlay={true}
        playsInline
        muted
        className="section-2-video align-bottom"
      >
        <source src="/section2bgvideo.mp4" type="video/mp4" />
      </video>
      <div className="sectin2-div">
        <div className="heading-div">
          <h3>Make your Own Jewellery</h3>
        </div>
        <br />
        <Container>
          <Row>
            <Col xs={0} sm={12} lg={6} md={6}>
              <div className="main-img1">
                <video
                  loop
                  autoPlay={true}
                  playsInline
                  muted
                  className="full-width-video align-bottom"
                  style={{ height: "100%", objectFit: "cover", width: "100%" }}
                >
                  <source src="/ladynecklaisvideo.mp4" type="video/mp4" />
                </video>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className="text-div">
                <h4>Lets see how you can make your own</h4>
                <p>
                  If you've ever seen a piece of Jewellery in an old photo or
                  online that you wished you could own, now you can make it a
                  reality. Simply send us the image, and we'll craft the
                  Jewellery exactly to your liking.{" "}
                </p>
                <p>
                  Whether it's a vintage design, a modern piece, or something
                  entirely unique, we'll bring your vision to life, ensuring it
                  fits your style and needs perfectly.
                </p>
                <p>
                  With our custom service, you can wear the Jewellery you've
                  always dreamed of, tailored just for you.
                </p>
              </div>
              <div className="deskPicture-section-2">
                <Row className="g-2">
                  <Col xs={6} sm={6} lg={6}>
                    <img
                      src="/ladyphoto.png"
                      alt=""
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "fill",
                      }}
                    />
                  </Col>
                  <Col xs={6} sm={6} lg={6}>
                    <img
                      src="/ladyphoto2.png"
                      alt=""
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "fill",
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <Row
                style={{ alignItems: "center", marginTop: "20px" }}
                className="text-div"
              >
                <Col xs={12} sm={5} md={6} lg={6} xl={5}>
                  <button
                    className="defult-btn-section-2 "
                    style={{ flex: 0.75, padding: "10px" }}
                    onClick={() =>
                      window.open(
                        "https://customize.luckystargold.com/",
                        "_blank"
                      )
                    }
                  >
                    Send Your Design
                  </button>
                </Col>
                <Col xs={12} sm={7} md={6} lg={6} xl={7}>
                  <div className="last-text">
                    Have a design in mind? Share it with us,
                    <br /> and we'll get back to you shortly!.
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Section2;
